.order-details {
    &__individual-order {
        align-items: center;
        background: rgba(23, 23, 23, 1);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        min-height: 60px;
        padding: 10px 0;
        color: $colors_primary_red;
        text-decoration: none !important;

        @include breakpoint(md) {
            min-height: 110px;
            padding: 0;
            background: rgba(23, 23, 23, 0.9);
            margin-bottom: 10px;
            border-radius: 4px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__tracking-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        flex-grow: 1;

        &--expanded {
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 110px;
        }
    }

    &__icon-text-container {
        display: flex;
        // align-self: flex-start;
        align-items: center;
        padding: 0 20px;
        width: 100%;
    }

    &__tracking-text-container {
        width: 100%;
        align-items: flex-start;
        line-height: 1.5;
        margin-left: 30px;
    }

    &__delivery-order-text {
        text-transform: uppercase;
        font-family: $font_family_main;
        font-size: 12px;
        color: $colors_primary_white;
    }

    &__delivery-order-status {
        width: 100%;
        position: relative;
        text-transform: uppercase;
        font-family: $font_family_heading_condensed;
        font-size: 20px;
        line-height: 1em;
        color: $colors_primary_white;
        margin-top: -3px;
        padding: 0.3em 0;
        margin-bottom: -0.25em;
    }

    &__order-items {
        color: rgb(151, 151, 151);
        font-family: $font_family_main;
        font-size: 12px;
        font-weight: normal;
        margin-right: 13px;

        flex-shrink: 0;

        &--remove {
            display: none;
        }
    }

    &__chevron-right {
        color: #b5b5b5;
        opacity: 0.5;
        padding-right: 25px;
    }

    @include breakpoint(md) {
        &__delivery-order-status {
            font-size: 24px;
            line-height: 1em;
        }

        &__order-items {
            font-size: 14px;
        }
    }
}
